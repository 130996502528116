export const ACTIONS_ID = {
  ADD_FILES_AND_FOLDERS: 'file_share_add_files_&_folders',
  CUSTOMIZE_APP: 'file_share_customize_app',
};

export const getAppManifestFunc = ({ appManifestBuilder }, editorSDK, t) => {
  return appManifestBuilder
    .configureManagementActions(async (managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction({
        title: t('appmanifest.mainAction.manageFiles&Folders'),
        icon: 'file_share_folder_open',
        actionId: ACTIONS_ID.ADD_FILES_AND_FOLDERS,
      });

      managementActionsBuilder.customActions().addAction({
        title: t('appmanifest.customAction.customizeDisplay'),
        icon: 'file_share_adjustments',
        type: 'editorActions',
        actionId: ACTIONS_ID.CUSTOMIZE_APP,
      });

      managementActionsBuilder.learnMoreAction().set({
        id: 'b7605423-9351-4d19-9299-892ff519e75b',
      });
    })
    .build();
};

export const openDashboard = (editorSDK) => {
  editorSDK.editor.openDashboardPanel('', {
    url: 'app/14c92d28-031e-7910-c9a8-a670011e062d',
    closeOtherPanels: false,
  });
};

export const openSettings = async (editorSDK) => {
  const allPages = await editorSDK.pages.data.getAll('');
  const fileSharePage =
    allPages &&
    allPages.find((page) => page.tpaPageId === 'wix_file_share_page');

  if (fileSharePage) {
    const fileSharePageId = fileSharePage.id;
    await editorSDK.document.pages.navigateTo('', {
      pageLink: { type: 'PageLink', pageId: fileSharePageId },
    });
    const fileShareComponentRef = await editorSDK.document.components.getById(
      '',
      { id: fileSharePageId },
    );
    const childrenComponentsFileShare = await editorSDK.components.getChildren(
      '',
      { componentRef: fileShareComponentRef },
    );
    const settingsFileShareId =
      childrenComponentsFileShare &&
      childrenComponentsFileShare.length &&
      childrenComponentsFileShare[0].id;
    settingsFileShareId &&
      (await editorSDK.editor.deeplink.show('', {
        type: 'component',
        params: [settingsFileShareId, 'settings'],
      }));
  }
};

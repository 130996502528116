import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import {
  getAppManifestFunc,
  openDashboard,
  openSettings,
  ACTIONS_ID,
} from './appManifest';
import { getTranslateFunction } from './i18n';

const faqAppDefId = '14c92d28-031e-7910-c9a8-a670011e062d';

let appToken;
let sdk;
let _translate;

const getFileShareEditorApi = () => ({
  async editorReady(_editorSDK, _appToken, { initialAppData }) {
    appToken = _appToken;
    sdk = _editorSDK;
    console.log('initialAppData.languageCode', initialAppData.languageCode);
    _translate = await getTranslateFunction(
      initialAppData.languageCode || 'en',
    );
    sdk.addEventListener('appActionClicked', (event) => {
      switch (event.detail.actionId) {
        case ACTIONS_ID.ADD_FILES_AND_FOLDERS:
          openDashboard(sdk);
          break;
        case ACTIONS_ID.CUSTOMIZE_APP:
          openSettings(sdk);
          break;
        default:
          break;
      }
    });
  },
  getAppManifest: ({ appManifestBuilder }, _editorSDK) =>
    getAppManifestFunc({ appManifestBuilder }, _editorSDK, _translate),
  handleAction(args) {
    const type = args.type;
    const payload = args.payload;
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case faqAppDefId: {
              return sdk.pages.data
                .getAll(appToken)
                .then((allPages) => {
                  const filesPage = allPages.find(
                    (page) =>
                      page.tpaPageId === 'files' &&
                      page.tpaApplicationId === faqAppDefId,
                  );
                  return (
                    filesPage &&
                    sdk.document.pages.navigateTo(appToken, {
                      pageLink: { type: 'PageLink', pageId: filesPage.id },
                    })
                  );
                })
                .then(() => sdk.document.save());
            }
            default:
              return Promise.resolve();
          }
        default:
          // console.log(type, payload);
          return Promise.resolve();
      }
    } catch (e) {
      Promise.reject();
      throw e;
    }
  },
});

const editorApp = withMembersArea(getFileShareEditorApi(), {
  membersAreaApps: [
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.FILE_SHARE,
  ],
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const getAppManifest = editorApp.getAppManifest;
